<div class="back-navigation" appActivitySection="QRView">
  <button class="back"
          appUserActivityClickTracking="Back"
  >
    ◁ Back to category
  </button>
</div>

<div class="box">
  <div class="container__image">
    <img src="/themes/original-joe/assets/images/appImg.jpg">
  </div>
  <ng-container>
    <div class="text">
      <div class="title">Scan to download</div>
    </div>
    <img class="qrImg" [src]="qrSrc">
    <img class="image" [src]="imgSrc" alt="">
  </ng-container>
</div>
