import { environment as original } from './environment.dev';

export const environment = {
  ...original,
  name: 'dev-original-joe',
  featureToggle: {
    ...original.featureToggle,
    withTabBar: true,
    withCallWaiter: false,
    withCallWaiterToPay: false,
    withCallWaiterToRepeat: false,
    withCallWaiterOnOffline: false,
    withInvoice: false,
    withServiceCentre: false,
    tabBarAutoHide: true,
    idleDelay: 30,
    theme: 'original-joe',
  }
};
